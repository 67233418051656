import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`10:00 EMOM of:`}</p>
    <p>{`5-Bench Press (60%1RM)`}</p>
    <p>{`5-Clapping Pushups`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9:`}</p>
    <p>{`Ring Dips`}</p>
    <p>{`CTB Pullups`}</p>
    <p>{`*`}{`100Ft Shuttle Run after each movement`}</p>
    <p>{`For time.`}</p>
    <p><em parentName="p">{`*`}{`compare to 6/2/19`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      